import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import HeaderAni from "../components/HeaderAni"
import Footer from "../components/Footer"

const Page = ({data}) => (
  <Layout key="index">
    <SEO title="About" />
    <HeaderAni></HeaderAni>
    <Header />
    <section className="pa3"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: data.allWordpressPage.edges[0].node.content,
                }}
              /> 
    <Footer></Footer>
  </Layout>
)

export const query = graphql`
  query ($id:String){
    allWordpressPage(filter: {id: {eq: $id}}) {
      edges {
        node {
          content
          id
        } 
      }
    }
  }
`;

export default Page

